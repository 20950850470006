import React from 'react';
import { Col, Row, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { FeatureLink } from 'common/components';
import oneDriveLogo from '../../../../resources/images/onedrive.png';

export default function MenuFeature() {
    const { t } = useTranslation();
    const history = useHistory();

    function handleFormForward() {
        history.push('/home/forms');
    }

    function handleGoogleDriveForward() {
        window.location.href =
            'https://phoenixinvestor-my.sharepoint.com/:f:/r/personal/headoffice_phoenix-investor_com/Documents/OneDrive%20PXI®?csf=1&web=1&e=M847eB';
    }

    function handleFinanceForward() {
        window.location.href =
            'https://www.fundmanager-pro.com/clients/47/IPS_4/Login';
    }

    function handleCalendarForward() {
        window.location.href = 'https://outlook.office.com/calendar/view/week';
    }

    return (
        <FeatureLink.Grid>
            <Row type="flex" gutter={20} style={{ marginBottom: '20px' }}>
                <Col span={12} onClick={handleFormForward}>
                    <FeatureLink
                        icon={<Icon type="question-circle" />}
                        title={t('homeFeature.form')}
                    />
                </Col>
                <Col span={12} onClick={handleGoogleDriveForward}>
                    <FeatureLink
                        icon={
                            <img
                                src={oneDriveLogo}
                                alt="OneDrive"
                                height="50"
                            />
                        }
                        title={`${t('homeFeature.cloudStorage')}`}
                    />
                </Col>
            </Row>
            <Row type="flex" gutter={20}>
                <Col span={12} onClick={handleFinanceForward}>
                    <FeatureLink
                        icon={<Icon type="credit-card" />}
                        title={t('homeFeature.financialOne')}
                    />
                </Col>
                <Col span={12} onClick={handleCalendarForward}>
                    <FeatureLink
                        icon={<Icon type="calendar" />}
                        title={t('homeFeature.calendar')}
                    />
                </Col>
            </Row>
        </FeatureLink.Grid>
    );
}
